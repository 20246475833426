import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length >= 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <p>
          You've found the notebook of a random internet stranger.
        </p>
        <div style={{ listStyle: `none` }}>
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <li key={post.fields.slug}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h3 className="frontpage-post-list-item">
                      <span className="frontpage-post-date">
                        <small>{post.frontmatter.date}</small>
                      </span>
                      <Link to={post.fields.slug} itemProp="url">
                        <span itemProp="headline" className="frontpage-post-title">{title}</span>
                      </Link>
                    </h3>
                    
                  </header>
                </article>
              </li>
            )
          })}
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
